a {
    text-decoration: none;
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    height: 48px;
    background: #cb3a40;
    order: 1;
    flex-grow: 0;
}

.tab {
    display: flex;
    flex-direction: row;
    gap: 0px;
    height: 46px;
    background: #ffffff;
    order: 2;
    flex-grow: 0;
    font-family: "Pretendard";
    font-style: normal;

    .on {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        box-shadow: inset 0px -4px 0px #aa0000;
        font {
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            color: #000000;
        }
    }

    .off {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #9095a2;
        cursor: pointer;
        font {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
        }
    }
}

.listRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 13px;
    gap: 29px;
    background: #ffffff;

    .photoBox {
        width: 80px;
        height: 80px;
        border-radius: 70%;
        overflow: hidden;
    }
    .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contentBox {
        flex: 1;

        .title {
            font-family: "Pretendard";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #000000;
        }
        .content {
            font-family: "Pretendard";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: #000000;
            margin-top: 10px;
        }
    }

    .btnOn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        width: 80px;
        height: 28px;

        background: #ff780e;
        border-radius: 8px;
    }
    .btnOff {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 80px;
        height: 28px;
        background: #777777;
        border-radius: 8px;
    }
    .btnProfile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 80px;
        height: 28px;
        margin-top: 10px;

        background: #bababa;
        border-radius: 8px;
    }

    font {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;
        color: #ffffff;
    }
}

.line {
    height: 0px;
    border: 1px solid #f1f1f1;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 400px 13px 10px;
    gap: 1px;
    background: #ffffff;

    .line {
        height: 1px;
        width: 100%;
        background-color: gray;
    }

    .title {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        color: #000000;
    }

    .content {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: #000000;
    }
}

.boroadcastingContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;
    position: relative;

    .btnBroadcastingOn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 98px;
        gap: 10px;
        background: #ff780e;
        border-radius: 5px;
        cursor: pointer;

        font {
            font-family: "Pretendard";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #ffffff;
        }
    }

    .btnBroadcastingOff {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 98px;
        gap: 10px;

        background: #d9d9d9;
        border-radius: 5px;

        font {
            font-family: "Pretendard";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;

            color: #000000;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 10px;
        width: 100%;
        gap: 4px;

        .title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 10px;
            gap: 10px;
            height: 22px;
            background: #ffffff;
        }

        .titleText {
            font-family: "Pretendard";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 160%;
            /* or 22px */

            color: #000000;
        }

        .content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 10px 0px 44px;
            gap: 10px;
        }

        .contentText {
            font-family: "Pretendard";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 160%;
            color: #000000;
        }
    }

    img {
        width: 100%;
    }
}

.titleDate {
    margin-left: 13px;
    margin-top: 15px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    color: #000000;
}

.line2 {
    margin: 2px 13px;
    height: 0px;
    border: 3px solid #bababa;
}

.emptyPhoto {
    width: 90%;
    margin-left: 5%;
    align-content: "center";
}
